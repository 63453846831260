console.log('It\'s working!');

$(document).ready(function () {
    console.log('index document::ready');

    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
        } else {
            $('.scrollup').fadeOut();
        }
    });

    $('.scrollup').click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });

    window.sr = ScrollReveal();
    sr.reveal('.icon');
    sr.reveal('.fa');
    sr.reveal('img');
    sr.reveal('.btn');

});

$(window).on('load', function () {
    console.log('index window::load');
});

$(window).resize(function () {
});